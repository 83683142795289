import React, {useState} from 'react';
import Header from "../layout/Header";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import QRScanner from "../QRScanner";

const Item = ({ data }: any) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    const openModal = () => {
        navigate('/percorsi/1')
    }

    return (
        <>
            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-[20px] pb-[70px]">
                <div className="relative flex flex-col items-center justify-center w-full lg:w-[75%]">
                    <img onClick={openModal} src={`/images/percorsi/thumbs/${data.image}`} alt="Percorso image" className="h-auto w-full md:w-auto cursor-pointer"/>
                </div>
                <h3 className="text-black text-sm md:text-base font-bold leading-[1.3] pt-[15px]">{data.title}</h3>
                <h4 className="text-[#ec651f] text-xs md:text-sm">{data.duration}</h4>
            </div>
        </>
    );
};

const Percorsi = () => {

    const data = [
        {title: 'Le donazioni Volponi', duration: 'Durata 30 minuti', image: 'percorso-1.png'},
        {title: 'Le donazioni Volponi', duration: 'Durata 30 minuti', image: 'percorso-2.png'},
        {title: 'Le donazioni Volponi', duration: 'Durata 30 minuti', image: 'percorso-3.png'},
        {title: 'Le donazioni Volponi', duration: 'Durata 30 minuti', image: 'percorso-1.png'},
        {title: 'Le donazioni Volponi', duration: 'Durata 30 minuti', image: 'percorso-2.png'},
        {title: 'Le donazioni Volponi', duration: 'Durata 30 minuti', image: 'percorso-3.png'},
    ]

    const [scanning, setScanning] = useState(false);

    // QR Code scanning
    const handleScanResult = (data: string) => {
        if (data) {
            window.location.href = data;
        }
    };

    const startScanner = () => {
        console.log('test')
        setScanning(true);
    };

    const stopScanner = () => {
        setScanning(false);
    };

    return (
        <>
            {scanning && <QRScanner onScan={handleScanResult} onClose={stopScanner}/>}
            <div className="relative flex flex-col items-start h-screen-dynamic w-screen overflow-hidden">
                <div className="w-full h-max z-[2] bg-[#e4dbd6]">
                    <Header version="dark"/>
                </div>
                <div className="flex flex-col w-full h-full lg:mt-[60px] md:mt-[30px] mt-[30px] px-[20px] md:px-[30px] lg:px-[100px] overflow-y-auto overflow-x-hidden pb-[50px] sm:pb-[80px]">
                    <div className="w-full flex flex-row items-center lg:gap-[30px] gap-[20px] mb-[40px] lg:mb-[60px] ml-[20px]">
                        <img src={`/images/gndm_shape_percorsi.png`} alt="Shape icon" className="h-[30px] lg:h-[50px]"/>
                        <h2 className="text-black md:text-[28px] lg:text-[40px] text-[28px] font-untitledSans uppercase mb-[-5px]">I percorsi</h2>
                    </div>
                    <div className="flex flex-wrap justify-start w-full pb-[100px] md:pb-0">
                        {data.map((item, _i) => {
                            return <Item key={`item-${_i}`} data={item}/>
                        })}
                    </div>
                </div>
            </div>
            <div className="flex sm:hidden absolute left-0 bottom-0 z-[900] w-full bg-black/95 h-[100px] flex-row items-center justify-center px-[20px]">
                {!scanning && <div onClick={startScanner} className="flex flex-col items-center gap-[7px] cursor-pointer">
                    <img src={`/images/icona-inquadra.png`} alt="Inquadra-icona" className="h-[30px]"/>
                    <span className="text-white text-xs text-center font-untitledSans">inquadra</span>
                </div>}
            </div>
            <div className="hidden sm:flex lg:hidden absolute left-0 bottom-0 z-[900] w-full bg-black/95 h-[60px] flex-row items-center justify-center px-[20px]">
                {!scanning && <div onClick={startScanner} className="flex flex-row items-center gap-[10px] cursor-pointer">
                    <img src={`/images/icona-inquadra.png`} alt="Inquadra-icona" className="h-[30px]"/>
                    <span className="text-white text-xs text-center font-untitledSans">inquadra</span>
                </div>}
            </div>
        </>
    )
};

export default Percorsi;
